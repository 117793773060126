 .intro {
     height: 550px;
     overflow-y: scroll;
 }

 #padingTop {
     padding-top: 88px;
 }

 .whatsAppIcon {
     z-index: 10000;
     /* background-color: white; */
     padding: 5px;
     position: fixed;
     bottom: 50px;
     right: 50px;
 }

 .whatsAppIcon img {
     cursor: pointer;
     height: 30px;
     width: 30px;
 }


 @media (max-width : 980px) {
     .whatsAppIcon {
         bottom: 25px;
         right: 25px;
     }

     #padingTop {
         padding-top: 73px;
     }
 }