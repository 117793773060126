@media (min-width:991px) {
    .responsive-button {
        display: none;

    }
}

@media (max-width:991px) {

    .responsive-button {
        display: block;

    }

    .p-header {
        padding-top: 3.2rem;
    }

    .responsive-header {
        /* width: 20rem; */

        position: fixed;
        height: 4rem;
        overflow: hidden;
        transition: all .5s;
    }


    .responsive-header.clicked {
        transition: .5s all;
        height: auto;
        overflow: unset;
        position: relative;

    }

    .responsive-logo {
        justify-content: space-between;
        /* border: 2px solid black; */
        width: 100%;
    }

    ul .container {
        margin-left: -3px;
    }

    .navbar-nav .dropdown-menu {
        /* position: static; */
        background: lavender;
    }

}

/* responsive img */
@media (min-width:1050px) {
    .p-img {
        display: block;
    }

    .p-img.responsive-img {
        display: none;
    }

    .e-com.contact-btn {
        display: block;
    }
}

@media (max-width:1050px) {

    .line-title {
        margin-left: 0 !important;
    }

    div.p-circle {
        left: 0;
    }

    .p-nav {
        justify-content: center !important;
    }

    .p-logo {
        margin-left: 2rem;
    }

    .p-img {
        display: none;
    }

    .p-img.responsive-img {
        display: block;
        margin-left: 0 !important;
        /* width: 98vw; */
        width: 20rem;
    }

    .e-com.contact-btn,
    .p-nav ul {
        display: none !important;
    }

    /* .info{
            margin-left: 0 !important;
            margin-bottom: 1rem !important;
            width: 92vw;
        } */
    /* .info ul{
            padding-left: 1rem !important;
        } */
}

@media (max-width:530px) {
    .p-img.responsive-img {
        display: block;
        margin-left: 2rem !important;
        margin-right: 2rem !important;
        /* width: 98vw; */
        width: 100%;
        position: relative;

    }
}

/* responsive img */

/* intro side bar */
@media (min-width:1050px) {

    .intro-menu {
        display: none !important;
    }

    .intro {
        /* height: 39rem; */
        /* overflow-y: scroll;
    scrollbar-width:thin; */
    }

    .intro::-webkit-scrollbar {
        width: 3px;
    }
}

@media (max-width:1050px) {
    .intro * {
        opacity: 0;
    }
    .intro .intro-menu {
        position: sticky;
        top: 0px;
    }
    .intro .intro-menu, .intro-menu *{
        opacity: 1;
    }

    .intro {
        top: 7rem;
        position: absolute;
        margin-left: -20rem;
        /* margin-left: -0rem; */
        z-index: 10;
        /* background-color: #070f1f; */
        /* background-image: linear-gradient(0deg, #070f1f 0%, #050c1d 100%); */
        /* background-image: linear-gradient(0deg, #070f1f 0%, #0b142b 100%); */
        transition: all 1s;
        width: 22rem;
    }

    .intro:hover *{
        opacity: 1;
    }

    .intro:hover {
        position: absolute;
        /* margin-left: -20rem; */
        margin-left: -0rem;
        z-index: 10;
        background-color: #070f1f;
        /* background-image: linear-gradient(0deg, #070f1f 0%, #050c1d 100%); */
        background-image: linear-gradient(0deg, #070f1f 0%, #0b142b 100%);
        box-shadow: -1px 2px 2px 1px rgba(255, 255, 255, 0.418);
    }

    .intro-menu {
        transition: .3s;
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important;
        /* background-color: #18d49c; */


    }

    .intro:hover .intro-menu {
        opacity: 0;
    }

    .intro-btn {
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
    }

    /* .intro-btn::after{
    content: '';
    position: absolute;
    width: 3.8rem;
    height: 10px;
    background: #1d1f35;
    left: 0;
    top: -5px;
border-radius: 50%;

}
.intro-btn::before{
    content: '';
    position: absolute;
    width: 3.8rem;
    height: 10px;
    background: #1d1f35;
    left: 0;
    bottom: -5px;
border-radius: 50%;
} */
    #p-swipper {
        width: 95% !important;
        margin-left: 5%;
    }

    .intro h4 {
        width: 17.3rem;
    }
}

@media (max-width:767px) {
    .intro h4 {
        width: 21.3rem;
    }
}

/* ////////////////////////////////////// */
.wobble-hor-bottom {
    -webkit-animation: wobble-hor-bottom 0.8s both;
    animation: wobble-hor-bottom 0.8s both;
}

.wobble-hor-bottom:hover {
    -webkit-animation: wobble-hor-bottom 0.8s both;
    animation: wobble-hor-bottom 0.8s both;
    opacity: .9;
    box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.595);
}


@-webkit-keyframes wobble-hor-bottom {

    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-30px) rotate(-6deg);
        transform: translateX(-30px) rotate(-6deg);
    }

    30% {
        -webkit-transform: translateX(15px) rotate(6deg);
        transform: translateX(15px) rotate(6deg);
    }

    45% {
        -webkit-transform: translateX(-15px) rotate(-3.6deg);
        transform: translateX(-15px) rotate(-3.6deg);
    }

    60% {
        -webkit-transform: translateX(9px) rotate(2.4deg);
        transform: translateX(9px) rotate(2.4deg);
    }

    75% {
        -webkit-transform: translateX(-6px) rotate(-1.2deg);
        transform: translateX(-6px) rotate(-1.2deg);
    }
}

@keyframes wobble-hor-bottom {

    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-30px) rotate(-6deg);
        transform: translateX(-30px) rotate(-6deg);
    }

    30% {
        -webkit-transform: translateX(15px) rotate(6deg);
        transform: translateX(15px) rotate(6deg);
    }

    45% {
        -webkit-transform: translateX(-15px) rotate(-3.6deg);
        transform: translateX(-15px) rotate(-3.6deg);
    }

    60% {
        -webkit-transform: translateX(9px) rotate(2.4deg);
        transform: translateX(9px) rotate(2.4deg);
    }

    75% {
        -webkit-transform: translateX(-6px) rotate(-1.2deg);
        transform: translateX(-6px) rotate(-1.2deg);
    }
}

/* ////////////////////////////////////// */

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 auto;


}

@media (min-width:1050px) {
    .center {
        display: none;
    }
}

.intro-btn {
    background: #C6C7DB;
    padding: 1px 12px;
    color: black;
}